import logo from "./KASALogo.png";
import "./App.css";
import "./input.css";

import weddingBg from "./weddingBg.png";
import weddingBg2 from "./weddingBg2.png";
// import Animation from "./Animation";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";

function App() {
  // const [showAnimation, setShowAnimation] = useState(true);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    question: "",
    message: "",
  })

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("https://formspree.io/f/mpzgwojj ", formData)
      .then((response) => {})
      .catch((error) => {
        swal({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="">Why do I have this issue?</a>',
        });
      })
      .finally(swal("Thanks.", "Hope to see you there!", "success"));
  };
  function  isSafari() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) 
  }
  
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowAnimation(false);
  //   }, 3000);

  //   return () => clearTimeout(timer);
  // }, []);
  
  return (
    <>
      <div className="">
        <div className="flex items-center justify-between w-full px-5 md:px-40">
          <div className="w-20 md:w-full">
            <img src={logo} alt="KSA" className="w-40 " />
          </div>
          <div>
            <ul className="flex gap-5  md:gap-20 text-[10px] md:text-xl items-center">
              <li>
                <a href="#WEDDING">WEDDING</a>
              </li>

              <li className="">
                <a href="#RSVP">RSVP</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex w-full text-center ">
        <h1 className="w-full mt-10 text ">Karim & Sarah</h1>
        </div>
        <div
          style={{
            display: " flex",
            flexDirection: "column",
            justifyContent: " center",
            alignItems: "center",
          }}
        >
          <p className="px-4 mt-10 text-center">
            Inspired by the Spanish word "casa" for home, KASA is a personalized
            term that symbolizes the unity of Karim and Sarah, forming a home
            filled with love and shared dreams
          </p>
          <img src={weddingBg} className="relative fade" alt="wedding" />
          <div className="absolute flex flex-col items-center justify-end gap-4 mt-60 2xl:gap-20 2xl:h-full 2xl:mt-40">
            <h1 className="flex flex-wrap items-center justify-center mx-20 mt-0 text-center md:mx-60 md:text-4xl 2xl:text-5xl">
              12.08.2023
            </h1>
            <p className="flex items-center justify-center mx-20 mt-10 text-xl text-center md:mx-60 2xl:text-4xl">
              Damascus.Syria
            </p>

            <h1 className="text-3xl md:text-xl 2xl:text-7xl">12</h1>
            <p className="text-2xl 2xl:text-5xl">August</p>
            <a href="#RSVP">
              <h1 className="    text-bg px-7 py-3 text-[10px] rounded-full  ">
                RSVP
              </h1>
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full gap-10 mt-10 md:mt-20">
          <div
            style={{
              display: " flex",
              flexDirection: "column",
              justifyContent: " center",
              alignItems: "center",
            }}
            id="WEDDING"
          >
            <h1 className="mt-10 text">THE WEDDING</h1>
          </div>
        </div>
        <div className="relative flex flex-col items-center justify-center w-full h-full mt-10 text-center 2xl:text-5xl 2xl:mt-60 ">
          <div style={{zIndex:'-55'}} className="absolute mx-14 2xl:max-w-[1100px]">
            <img src={weddingBg2} className="fade" alt="wedding" />
          </div>
          <div className="flex flex-col items-center   text-center mx-20 gap-10 2xl:gap-40 2xl:max-w-[1000px]">
            <p>The wedding will take place in Ebla Hotel, Damascus at 8:30PM</p>
            {!isSafari()?
              <a href=" https://maps.app.goo.gl/MyGDDDzYBKNWWwkE9?g_st=ic" target="_blank" rel="noopener noreferrer">
            <h1 className="text-bg px-7 py-3 text-[10px] rounded-full mt-5 2xl:mt-20">
                LOCATION
            </h1>
              </a>:  <a href="https://maps.apple.com/?address=Ebla%20Cham%20Golf%20Course,%20Maliha,%20Syria&ll=33.428385,36.382024&q=Ebla%20Cham%20Golf%20Course&t=m" target="_blank" rel="noopener noreferrer">
            <h1 className="text-bg px-7 py-3 text-[10px] rounded-full mt-5 2xl:mt-20">
                LOCATION
            </h1>
          </a>  
          }
          </div>
        </div>

        <section>
          <div
            style={{
              display: " flex",
              flexDirection: "column",
              justifyContent: " center",
              alignItems: "center",
              marginTop: "15%",
            }}
          >
            <h1 className="mt-10 text">RSVP</h1>
            <p className="flex flex-wrap items-center justify-center mx-10 mt-10 text-center md:mt-32 md:mx-60 2xl:mt-96 md:text-4xl 2xl:text-5xl w-1/1 2xl:w-1/3">
              Please RSVP before 3 August 2023!
            </p>
          </div>
        </section>
        <section id="RSVP">
          <form onSubmit={handleSubmit} className="flex flex-col gap-10 px-5">
            <div className="flex flex-col gap-2">
              <label>
                <p>First Name</p>
              </label>
              <input
                type="text"
                id="name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
                className="bg-transparent border border-1 border-black/50 placeholder:p-2"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label>
                <p>Last Name</p>
              </label>
              <input
                type="text"
                id="name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
                className="bg-transparent border border-1 border-black/50 placeholder:p-2"
              />
            </div>

            <div>
              <p>Will you be joining us on August 12th?</p>
              <div className="flex gap-10 mt-5">
                <div className="flex items-center gap-5">
                  <input
                    type="radio"
                    id="yes"
                    name="question"
                    value="yes"
                    onChange={handleChange}
                  />
                  <label htmlFor="yes">
                    <p>Yes</p>
                  </label>
                </div>
                <div className="flex items-center gap-5">
                  <input
                    type="radio"
                    id="no"
                    name="question"
                    value="no"
                    onChange={handleChange}
                  />
                  <label htmlFor="no">
                    <p>No</p>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label>
                <p>Leave us a message</p>
              </label>
              <textarea
                type="text"
                id="name"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="bg-transparent border border-1 border-black/50 placeholder:p-2"
              />
            </div>
            <div className="">
              <button
                className="w-40 py-2 text-white rounded-full bg-gradient-to-r from-pink-300 via-indigo-500 to-blue-700"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </section>
      </div>
    </>
  );
}

export default App;
